import $ from 'jquery';
import Registry from "./Registry";
import SmoothScroll from "smooth-scroll";
import {Fancybox} from "@fancyapps/ui";
import CookiePolicyPopup from "../utils/CookiePolicyPopup";
import {$Doc} from '../utils/jQueryGlobals'

export default class Context {
    constructor(config) {
        this.config = config;
        this.loadComponents();
        this.defaultContext();
    }

    defaultContext() {
        new CookiePolicyPopup();

        this.scroll = new SmoothScroll('a[href*="#"]', {
            ignore: '[data-scroll-ignore]',
            speed: 800,
            durationMax: 1000,
            durationMin: 200,
            easing: 'easeInOutCubic',
        });

        this.onRegistration = function (component, _response) {
            window.parent.location = "https://www.clarins.it/concorso-thank-you/";
        }

        this.onBodyFitAprile2023Generated = function (_component, response) {
            Fancybox.show([{
                src: '#thank-you',
                type: 'inline',
            }], {
                mainClass: 'thank-you', autoFocus: false,
                placeFocusBack: false,
                dragToClose: false,
                click: false,
            })

            const codes15Count = response.codes15Count;
            const codes10Count = response.codes10Count;
            $Doc.find('[data-body-fit-aprile-2023-buono-15-count]').text(codes15Count.toString().padStart(4, '0'));
            $Doc.find('[data-body-fit-aprile-2023-buono-10-count]').text(codes10Count.toString().padStart(4, '0'));
        }

        this.onCppBarCodeTestGenerated = function (_component, response) {
            $Doc.find('.response-message').text('');
            const codesCount = response.codesCount;
            $Doc.find('[data-cpp-bar-code-test-count]').text(codesCount.toString().padStart(4, '0'));

            $('#omaggio')
                .focus()
                .parents('form').trigger('reset');
        }

        this.onCppNovembre2023Generated = function (_component, response) {
            $Doc.find('.response-message').text('');
            const codesCount = response.codesCount;
            $Doc.find('[data-cpp-novembre-2023-count]').text(codesCount.toString().padStart(4, '0'));

            $('#omaggio')
                .parents('form').trigger('reset');
        }

        this.onCppDicembre2023Generated = function (_component, response) {
            $Doc.find('.response-message').text('');
            const codesCount = response.codesCount;
            $Doc.find('[data-cpp-dicembre-2023-count]').text(codesCount.toString().padStart(4, '0'));

            $('#omaggio')
                .parents('form').trigger('reset');
        }


        this.onCppGennaio2024Generated = function (_component, response) {
            $Doc.find('.response-message').text('');
            const codesCount = response.codesCount;
            $Doc.find('[data-cpp-gennaio-2024-count]').text(codesCount.toString().padStart(4, '0'));

            $('#omaggio')
                .parents('form').trigger('reset');
        }

        this.onCppMaggio2024Generated = function (_component, response) {
            $Doc.find('.response-message').text('');
            const codesCount = response.codesCount;
            $Doc.find('[data-cpp-maggio-2024-count]').text(codesCount.toString().padStart(4, '0'));

            $('#omaggio')
                .parents('form').trigger('reset');
        }

        this.onCppGiugno2024Generated = function (_component, response) {
            $Doc.find('.response-message').text('');
            const codesCount = response.codesCount;
            $Doc.find('[data-cpp-giugno-2024-count]').text(codesCount.toString().padStart(4, '0'));

            $('#omaggio')
                .parents('form').trigger('reset');
        }

        this.onCppLuglio2024Generated = function (_component, response) {
            $Doc.find('.response-message').text('');
            const codesCount = response.codesCount;
            $Doc.find('[data-cpp-luglio-2024-count]').text(codesCount.toString().padStart(4, '0'));

            $('#omaggio')
                .parents('form').trigger('reset');
        }

        this.onCppAgosto2024Generated = function (_component, response) {
            $Doc.find('.response-message').text('');
            const codesCount = response.codesCount;
            $Doc.find('[data-cpp-agosto-2024-count]').text(codesCount.toString().padStart(4, '0'));

            $('#omaggio')
                .parents('form').trigger('reset');
        }

        this.onCppSettembre2024Generated = function (_component, response) {
            $Doc.find('.response-message').text('');
            const codesCount = response.codesCount;
            $Doc.find('[data-cpp-settembre-2024-count]').text(codesCount.toString().padStart(4, '0'));

            $('#omaggio')
                .parents('form').trigger('reset');
        }

        this.onCppOttobre2024Generated = function (_component, response) {
            $Doc.find('.response-message').text('');
            const codesCount = response.codesCount;
            $Doc.find('[data-cpp-ottobre-2024-count]').text(codesCount.toString().padStart(4, '0'));

            $('#omaggio')
                .parents('form').trigger('reset');
        }

        this.onCppNovembre2024Generated = function (_component, response) {
            $Doc.find('.response-message').text('');
            const codesCount = response.codesCount;
            $Doc.find('[data-cpp-novembre-2024-count]').text(codesCount.toString().padStart(4, '0'));

            $('#omaggio')
                .parents('form').trigger('reset');
        }

        this.onCppDicembre2024Generated = function (_component, response) {
            $Doc.find('.response-message').text('');
            const codesCount = response.codesCount;
            $Doc.find('[data-cpp-dicembre-2024-count]').text(codesCount.toString().padStart(4, '0'));

            $('#omaggio')
                .parents('form').trigger('reset');
        }

        this.onCppMaggio2024Generated = function (_component, response) {
            $Doc.find('.response-message').text('');
            const codesCount = response.codesCount;
            $Doc.find('[data-cpp-maggio-2024-count]').text(codesCount.toString().padStart(4, '0'));

            $('#omaggio')
                .parents('form').trigger('reset');
        }

        this.onPromo10EuroGenerated = function (_component, response) {
            $Doc.find('.response-message').text('');
            const codesCount = response.codesCount;
            $Doc.find('[data-promo-10-euro-count]').text(codesCount.toString().padStart(4, '0'));

            $('#omaggio')
                .parents('form').trigger('reset');
        }

        this.onCppFebbraio2024Generated = function (_component, response) {
            $Doc.find('.response-message').text('');
            const codesCount = response.codesCount;
            $Doc.find('[data-cpp-febbraio-2024-count]').text(codesCount.toString().padStart(4, '0'));

            $('#omaggio')
                .parents('form').trigger('reset');
        }

        this.onCppMarzo2024Generated = function (_component, response) {
            $Doc.find('.response-message').text('');
            const codesCount = response.codesCount;
            $Doc.find('[data-cpp-marzo-2024-count]').text(codesCount.toString().padStart(4, '0'));

            $('#omaggio')
                .parents('form').trigger('reset');
        }

        this.onCppAprile2024Generated = function (_component, response) {
            $Doc.find('.response-message').text('');
            const codesCount = response.codesCount;
            $Doc.find('[data-cpp-aprile-2024-count]').text(codesCount.toString().padStart(4, '0'));

            $('#omaggio')
                .parents('form').trigger('reset');
        }

        this.onCppGennaio2024Generated = function (_component, response) {
            $Doc.find('.response-message').text('');
            const codesCount = response.codesCount;
            $Doc.find('[data-cpp-gennaio-2024-count]').text(codesCount.toString().padStart(4, '0'));

            $('#omaggio')
                .parents('form').trigger('reset');
        }

        this.onPromo10EuroGenerated = function (_component, response) {
            $Doc.find('.response-message').text('');
            const codesCount = response.codesCount;
            $Doc.find('[data-promo-10-euro-count]').text(codesCount.toString().padStart(4, '0'));

            $('#omaggio')
                .parents('form').trigger('reset');
        }


        this.onCppOttobre2023Generated = function (_component, response) {
            Fancybox.show([{
                src: '#thank-you',
                type: 'inline',
            }], {
                mainClass: 'thank-you', autoFocus: false,
                placeFocusBack: false,
                dragToClose: false,
                click: false,
            });

            const codesCount = response.codesCount;
            $Doc.find('[data-cpp-ottobre-2023-count]').text(codesCount.toString().padStart(4, '0'));
        }

        this.onCppSettembre2023Generated = function (_component, response) {
            Fancybox.show([{
                src: '#thank-you',
                type: 'inline',
            }], {
                mainClass: 'thank-you', autoFocus: false,
                placeFocusBack: false,
                dragToClose: false,
                click: false,
            });

            const codesCount = response.codesCount;
            $Doc.find('[data-cpp-settembre-2023-count]').text(codesCount.toString().padStart(4, '0'));
        }

        if (document.getElementById('savePromoBarCode')) {
            $('#omaggio').on('keydown', function (event) {
                if (event.key === 'Enter') {
                    event.preventDefault();
                    document.getElementById('promo').focus();
                }
            });

            $('#promo').on('keydown', function (event) {
                if (event.key === 'Enter') {
                    event.preventDefault();
                    document.getElementById('promo').focus();
                }
            });
        }
     }

    loadComponents(name = false, context) {
        let registry = new Registry(),
            selector = '[data-g-component]';
        if (name) {
            selector = '[data-g-component="' + name + '"]';
        }

        $(selector, context).each((index, elem) => {
            if (!$(elem).data('g-component-initialized')) {
                let Cmp = registry.getComponent($(elem).data('g-component'));
                if (typeof Cmp === 'function') {
                    new Cmp(elem, this);
                    $(elem).data('g-component-initialized', true);
                }
            }
        });
    }
}
